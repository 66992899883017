import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <section className="main_top" id="about">
      <div className="inner">
        <h1>Hi,</h1>
        <p>
          I'm Błażej.{" "}
          <a
            href="http://www.linkedin.com/in/blazej-orlowski-4602b9b2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Front-End Developer
          </a>{" "}
          specialized in fully custom web development. Working in the past with
          multiple companies and agencies where I collected some big amount of
          experience. Right now I'm focus in learning of{" "}
          <a
            href="https://github.com/orlowskiblazej"
            target="_blank"
            rel="noopener noreferrer"
          >
            ReactJS
          </a>
          . Training and racing{" "}
          <a
            href="https://www.strava.com/athletes/blazej-orlowski"
            target="_blank"
            rel="noopener noreferrer"
          >
            MTB
          </a>{" "}
          &{" "}
          <a
            href="https://www.strava.com/athletes/blazej-orlowski"
            target="_blank"
            rel="noopener noreferrer"
          >
            Road
          </a>{" "}
          bikes. In short.. passionate in cycling!
        </p>
        <ul className="list-unstyled list-inline">
          <li>GatsbyJS</li>
          <li>GraphQL</li>
          <li>React</li>
          <li>jQuery</li>
          <li>Wordpress</li>
          <li>PHP</li>
          <li>Laravel</li>
          <li>HTML5</li>
          <li>SASS & BEM</li>
          <li>Git</li>
          <li>Gulp</li>
          <li>Webpack</li>
          <li>Adobe / Affinity</li>
          <li>Sketch</li>
          <li>Scrum</li>
        </ul>
        <a href="#career" className="btn_custom">
          Keep scrolling ...
        </a>
      </div>
    </section>

    <section className="main_career" id="career">
      <h1 className="page_header">Career</h1>
      <div className="cv_main">
        <div className="cv_single">
          <div className="row">
            <div className="col-sm-3 left_col">
              <p className="date">9.2018 - current</p>
            </div>
            <div className="col-sm-9 right_col">
              <p className="position">Front-End Developer</p>
              <p className="company">
                Group ADream <span>Kraków</span>
              </p>
            </div>
          </div>
        </div>

        <div className="cv_single">
          <div className="row">
            <div className="col-sm-3 left_col">
              <p className="date">9.2017 - 7.2018</p>
            </div>
            <div className="col-sm-9 right_col">
              <p className="position">Front-End Developer</p>
              <p className="company">
                Dots+Circles LTD <span>UK/remote</span>
              </p>
            </div>
          </div>
        </div>

        <div className="cv_single">
          <div className="row">
            <div className="col-sm-3 left_col">
              <p className="date">8.2016 - 4.2018</p>
            </div>
            <div className="col-sm-9 right_col">
              <p className="position">Front-End Developer</p>
              <p className="company">
                SupportDevs - Software House <span>Katowice/remote</span>
              </p>
            </div>
          </div>
        </div>

        <div className="cv_single">
          <div className="row">
            <div className="col-sm-3 left_col">
              <p className="date">7.2017 - 3.2018</p>
            </div>
            <div className="col-sm-9 right_col">
              <p className="position">Front-End Developer</p>
              <p className="company">
                EveryWhere Agency <span>Kraków/remote</span>
              </p>
            </div>
          </div>
        </div>

        <div className="cv_single">
          <div className="row">
            <div className="col-sm-3 left_col">
              <p className="date">12.2016 - 5.2016</p>
            </div>
            <div className="col-sm-9 right_col">
              <p className="position">Wordpress Lead Developer</p>
              <p className="company">
                Softnauts - Mobile and Web Software House <span>Kraków</span>
              </p>
            </div>
          </div>
        </div>

        <div className="cv_single">
          <div className="row">
            <div className="col-sm-3 left_col">
              <p className="date">11.2015 - 11.2016</p>
            </div>
            <div className="col-sm-9 right_col">
              <p className="position">Front-End Developer</p>
              <p className="company">
                Veray Interactive <span>Kraków/remote</span>
              </p>
            </div>
          </div>
        </div>

        <div className="cv_single">
          <div className="row">
            <div className="col-sm-3 left_col">
              <p className="date">11.2015 - 3.2016</p>
            </div>
            <div className="col-sm-9 right_col">
              <p className="position">Front-End Developer</p>
              <p className="company">
                Sky-Shop.pl <span>Kraków</span>
              </p>
            </div>
          </div>
        </div>

        <div className="cv_single">
          <div className="row">
            <div className="col-sm-3 left_col">
              <p className="date">3.2015 - 11.2015</p>
            </div>
            <div className="col-sm-9 right_col">
              <p className="position">Web Developer</p>
              <p className="company">
                KKM Dystrybucja <span>Kraków</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
